/* eslint-disable camelcase */
import { UC } from '@sdp.nd/nd-uc-sdk'
import buildURL from 'axios/lib/helpers/buildURL'
import { appId } from 'cfg/env'
import { ucConfig } from 'cfg/uc'


const sdpEnv = process.env.NODE_ENV
const LSUserKey = 'X-EDU-ADMIN-USER'

export const uc = new UC({
  env: sdpEnv || 'aom-ai-beta',
  sdpAppId: appId,
  origins: ucConfig.origins
})

function getDomain() {
  const hostname = ucConfig.host
  const isOnline = hostname === window.location.hostname
  if (isOnline) {
    return '/uc-component/index.html'
  } else {
    return `https://${hostname}/uc-component/index.html`
  }
}

export function getLoginUrl() {
  const domain = getDomain()
  return `${domain}?sdp-app-id=${appId}#/login?redirect_uri=${encodeURIComponent(window.location.href)}&send_uckey=true`
}

export function getUCSettingUrl() {
  const domain = getDomain()
  return `${domain}?sdp-app-id=${appId}&sso=false#/`
}

let isLogin = null
let userInfo = null

export function setUserInfo(val) {
  isLogin = true
  userInfo = val
}

export async function getLoginState() {
  if (typeof isLogin === 'boolean') {
    return isLogin
  }
  isLogin = await uc.isLogin()
  return isLogin
}

export function getCacheLoginState() {
  return isLogin
}

export function cacheUserInfo(val) {
  // 目前只缓存当前有用信息
  const {
    real_name, user_id, avatar, nick_name
  } = val
  window.localStorage.setItem(LSUserKey, JSON.stringify({
    real_name,
    user_id,
    avatar,
    nick_name
  }))
}

export async function getUserInfo() {
  if (userInfo) {
    return userInfo
  } else {
    const account = uc.getCurrentAccount()
    if (account) {
      const curUserInfo = await account.getAccountInfo()
      const { user_id } = curUserInfo
      const avatar = uc.getAvatarURL({
        userId: user_id
      })
      setUserInfo({
        ...curUserInfo,
        avatar
      })
      cacheUserInfo({
        ...curUserInfo,
        avatar
      })
      return userInfo
    }
  }
  return undefined
}

export async function checkLoginState() {
  const hasLogin = await getLoginState()
  if (hasLogin) {
    const remoteUser = await getUserInfo()
    return remoteUser
  }
  return undefined
}

export async function getAuthHeader(c) {
  const hasLogin = await getLoginState()
  if (hasLogin) {
    const {
      url: _url,
      method,
      params,
      paramsSerializer
    } = c
    const url = buildURL(_url, params, paramsSerializer).replace(/@/g, '%40').replace(/'/g, '%27')
    const auth = await uc.getAuthHeaderAsync({
      url,
      method
    })
    return auth
  }
  return ''
}


export async function logout() {
  try {
    await uc.logout()
  } finally {
    isLogin = null
    userInfo = null
  }
}

// 用户是否首次在此浏览器中登录
export function isFirstLogin(userId) {
  if (!userId) return false
  if (window.localStorage.getItem(`${userId}_first_login_in_x_cloud_web`)) return false
  window.localStorage.setItem(`${userId}_first_login_in_x_cloud_web`, userId)
  return true
}
