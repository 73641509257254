import { env } from './env'

export const UCConfig = {
  'aom-ai-beta': {
    host: 'uc-component.th2-staging.apse1.ndpg.xyz',
       origins: {
      UC: 'https://uc-gateway.th2-staging.apse1.ndpg.xyz',
      SSO: 'https://uc-sso.th2-staging.apse1.ndpg.xyz'
    },

  },
  'aom-ai': {
    host: 'uc-component.prod.aom-ai.com',
       origins: {
      UC: 'https://uc-gateway.prod.aom-ai.com',
      SSO: 'https://uc-sso.prod.aom-ai.com'
    },

  }
}

export const ucConfig = UCConfig[env] || {}
