import { getQueryStringByName } from "modules/common/urlHelper"

const Env = {
  'aom-ai-beta': 'aom-ai-beta',
  'aom-ai': 'aom-ai'
}

const SdpAppId = {
  'aom-ai-beta': '52dee6da-4350-4ff9-a9e5-309ce2880721',
  'aom-ai': '52dee6da-4350-4ff9-a9e5-309ce2880721'
}

const CDNHost = {
  'aom-ai-beta': '//cdncs.th2-staging.apse1.ndpg.xyz',
  'aom-ai': '//cdncs.th2-staging.apse1.ndpg.xyz'
}

const CDNReadHost = {
  'aom-ai-beta': '//cdncs.th2-staging.apse1.ndpg.xyz',
  'aom-ai': '//cdncs.th2.apse1.ndpg.xyz'
}

const CDNPrefix = {
  'aom-ai-beta': '/v0.1/static',
  'aom-ai': '/v0.1/static'
}

const FormatCode = {
  'aom-ai-beta': 'aom-ai-beta',
  'aom-ai': 'aom-ai'
}

export const ResProfileHost = {
  'aom-ai-beta': 'https://e-resource-profile-web.th2-staging.apse1.ndpg.xyz',
  'aom-ai': 'https://e-resource-profile-web.prod.aom-ai.com'
}

const currentEnv = process.env.NODE_ENV || 'aom-ai-beta'

// 先直接用预生产来试
export const env = Env[currentEnv]
// export const env = Env['preproduction' || currentEnv] // 本地测试用

const appIdFromURL = getQueryStringByName('sdp-app-id', window.location.search)
export const appId = appIdFromURL || SdpAppId[env]
export const orgId = getQueryStringByName('sdp-org-id', window.location.search)
export const formatCode = FormatCode[env]

export const cdnHost = CDNHost[env]
export const cdnReadHost = CDNReadHost[env]
export const cdnPrefix = CDNPrefix[env]
export const resProfileHost = ResProfileHost[env]
