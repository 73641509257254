// 无用
export default {
  'aom-ai-beta': {
    UC: 'https://uc-gateway.th2-staging.apse1.ndpg.xyz',
    SSO: 'https://uc-sso.th2-staging.apse1.ndpg.xyz',
    OMS: 'https://uc-gateway.th2-staging.apse1.ndpg.xyz',
    OMS_SEARCH: 'https://oms-search.th2-staging.apse1.ndpg.xyz',
    RBAC: 'https://rbac.th2-staging.apse1.ndpg.xyz'
  },
  'aom-ai': {
    UC: 'https://uc-gateway.prod.aom-ai.com',
    SSO: 'https://uc-sso.prod.aom-ai.com',
    OMS: 'https://uc-gateway.prod.aom-ai.com',
    OMS_SEARCH: 'https://oms-search.prod.aom-ai.com',
    RBAC: 'https://rbac.prod.aom-ai.com'
  }
}
