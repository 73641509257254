import { env } from './env'

export const BizCourseGateway = {
  'aom-ai-beta': 'https://x-course.th2-staging.apse1.ndpg.xyz',
  'aom-ai': 'https://x-course.prod.aom-ai.com'
}

export const BizCourseAPI = {
  'aom-ai-beta': 'https://x-course-api.th2-staging.apse1.ndpg.xyz',
  'aom-ai': 'https://x-course-api.prod.aom-ai.com'
}

export const FeedBookApi = {
  'aom-ai-beta': 'http://audit-gateway.th2-staging.apse1.ndpg.xyz',
  'aom-ai': 'http://audit-gateway.prod.aom-ai.com'
}

export const AuditApi = {
  'aom-ai-beta': 'http://audit-gateway.th2-staging.apse1.ndpg.xyz',
  'aom-ai': 'http://audit-gateway.prod.aom-ai.com'
}

export const bizCourseGatewayHost = BizCourseGateway[env]
export const bizCourseAPIHost = BizCourseAPI[env]
export const feedBookHost = FeedBookApi[env]
export const auditApiHost = AuditApi[env]
